/* Bootstrap overrides
-------------------------------------------------- */

  $body: #000;
  $headings-font-weight: 300 !important;
$primary: #1b46c2;
  $light: #fafafa;

  $headings-font-weight: 300 !important;
  $a: #1392ff;

/* Do not move the imports from here.
Override Bootstrap variables only above.
-------------------------------------------------- */

// Bootstrap

@import '~bootstrap/scss/bootstrap.scss';

@import '~bootstrap/scss/reboot';



.custom-classname.btn-primary {
  background-color: green;
}

/* Base
-------------------------------------------------- */
@import 'assets/sass/include-media.scss';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');
* { 
  font-family: 'Montserrat', sans-serif;
}

html {
  min-height: 100%;
  font-size: 14px;
}

.wrapper {
  min-height: 100vh;
}

.opacity-6 {
  opacity: 0.6;
}

.dapp-icon {
  padding: 5px;
  border-radius: 50%;
  background-color: $light;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  &.icon-medium {
    width: 80px;
    height: 80px;
  }
}

/* Unlock
-------------------------------------------------- */
.dapp-core-ui-component {
  .card {
    border-radius: 24px;
    border: none;
    padding-left: 12px;
    padding-right: 12px;
  }

  .web-wallet-login_wrapper, .ledger-login_wrapper, .test-class_name_wrapper, .wallect-connect-login_wrapper, .extension-login_noExtensionButtonWrapper {
    border-radius: 10rem;
  }
  .extension-login_noExtensionButtonWrapper {
    border: none;
    padding-top: 10px;
    background-color: #FAFAFA;
  }
}

/* Navbar
-------------------------------------------------- */
.elrond-logo {
  width: 85px;
  height: 25px;
}

.dapp-name {
  line-height: 1;
  border-left: 1px solid $gray-400;
  padding-left: 3px;
  margin-left: 4px;
}

/* Dashboard
-------------------------------------------------- */
.action-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $white;
  margin: 0 7px;

  button {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: $white;

    &:hover {
      opacity: 0.9;
    }
  }
}

.transactions {
  .transaction-icon {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  &.table {

    .transaction-hash .trim {
      @include media-breakpoint-down(xl) {
        max-width: none;
      }
      @include media-breakpoint-down(lg) {
        max-width: 26rem;
      }
      @include media-breakpoint-down(md) {
        max-width: 12rem;
      }
      @include media-breakpoint-down(sm) {
        max-width: 8rem;
      }
    }

    td, tr {
      padding: 0.65rem;
    }

    td {
      white-space: nowrap;
      vertical-align: middle;
    }

    tr:last-of-type {
      td {
        border-bottom: 1px solid #dee2e6;
      }
    }
  }
}

/* Actions
-------------------------------------------------- */

.not-allwed {
  cursor: not-allowed;
}

/* Loader, Transaction success/Fail
-------------------------------------------------- */
.page-state {
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.1);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .bg-blue .lds-ellipsis div {
    background: rgba(255, 255, 255, 0.4);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(19px, 0);
    }
  }
}

/* Footer
-------------------------------------------------- */
footer {
  div {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    display: inline-block;
    font-weight: 400;
    line-height: 1.155rem;

    a {
      color: $black;

      &:hover {
        color: $black;
      }

      #heart {
        height: 16px;
        width: 16px;

        path {
          fill: #c00;
        }
      }
    }
  }
}

/* Custom Modal
-------------------------------------------------- */
.custom-modal {
  position: absolute;
  z-index: 100;
  top: 24px;
  right: 24px;
  border-radius: 24px;
  padding: 8px;

  @include media-breakpoint-down(sm) {
    top: 25vh;
    right: 0;
    left: 0;
    bottom: 0;
    border-radius: 24px 24px 0 0;
  }

  @include media-breakpoint-up(sm) {
    width: 500px;
  }

  .custom-modal-header {
    padding: 8px;
    display: flex;
    justify-content: space-between; 

    .btn-close {
      border-radius: 50%;
      background-color: #eee;
      border: 4px solid #eee;

      &:hover {
        transition: background-color #ddd;
        transition-duration: 200ms;
      }
    }
  }
}

.dapp-core-ui-component .modal-content, .modal-content {
  border-radius: 24px;
  padding: 8px;

  .modal-header {
    .btn-close {
      border-radius: 50%;
      background-color: #eee;
      border: 4px solid #eee;

      &:hover {
        transition: background-color #ddd;
        transition-duration: 200ms;
      }
    }
  }

  .modal-footer {
    border-top: none;
  }
}

// $breakpoints: (phone: 640px, 
//                tablet: 768px, 
//                desktop: 1024px) !default;

// @include media("<tablet") {
//   .custom-modal {
//     top: 12px;
//     right: 12px;
//     left: 12px;
//     bottom: 12px;
//   }
// }

/* Buttons
-------------------------------------------------- */
.btn {
  border-radius: 10rem;
}


/* Loader
-------------------------------------------------- */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(90deg);
  }
  35% {
    transform: rotate(90deg);
  }
  45% {
    transform: rotate(180deg);
  }
  60% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  85% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  10% {
    -webkit-transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotate(90deg);
  }
  35% {
    -webkit-transform: rotate(90deg);
  }
  45% {
    -webkit-transform: rotate(180deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
  }
  85% {
    -webkit-transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes bounce {
  0% {
    transform: scaleX(1);
  }
  10% {
    transform: scaleX(1);
  }
  20% {
    transform: scaleX(0.8);
  }
  35% {
    transform: scaleX(0.8);
  }
  45% {
    transform: scaleX(1);
  }
  60% {
    transform: scaleX(1);
  }
  75% {
    transform: scaleX(0.8);
  }
  85% {
    transform: scaleX(0.8);
  }
  100% {
    transform: scaleX(1);
  }
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: scaleX(1);
  }
  10% {
    -webkit-transform: scaleX(1);
  }
  20% {
    -webkit-transform: scaleX(0.8);
  }
  35% {
    -webkit-transform: scaleX(0.8);
  }
  45% {
    -webkit-transform: scaleX(1);
  }
  60% {
    -webkit-transform: scaleX(1);
  }
  75% {
    -webkit-transform: scaleX(0.8);
  }
  85% {
    -webkit-transform: scaleX(0.8);
  }
  100% {
    -webkit-transform: scaleX(1);
  }
}

.loader {
  box-sizing: content-box;
  height: 40px;
  margin: 0 auto 10px auto;
  position: relative;
  width: 70px;

  &:before {
    border: 2px solid #111;
    box-sizing: content-box;
    content: '';
    height: 0;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 0;
  }

  .spin {
    -webkit-animation: spin 2.25s linear infinite;
    animation: spin 2.25s linear infinite;
    height: 14px;
    left: 28px;
    position: absolute;
    top: 13px;
    width: 14px;

    &:before {
      border: 3px solid #111;
      box-sizing: content-box;
      content: '';
      height: 14px;
      left: 50%;
      position: absolute;
      top: 50%;
      -webkit-transform: translate(-50%, -50%) rotate(45deg);
      transform: translate(-50%, -50%) rotate(45deg);
      width: 14px;
    }
  }

  .bounce {
    -webkit-animation: bounce 2.25s linear infinite;
    animation: bounce 2.25s linear infinite;
    height: 100%;
    margin: 0 auto;
    position: relative;
    width: 100%;

    &:after,
    &:before {
      box-sizing: content-box;
      content: '';
      height: 10px;
      position: absolute;
      top: 13px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      width: 10px;
    }

    &:before {
      border-bottom: 3px solid #111;
      border-left: 3px solid #111;
      left: 0;
    }

    &:after {
      border-right: 3px solid #111;
      border-top: 3px solid #111;
      right: 0;
    }
  }
}